import React from "react";
import "./Login.css";
import { useState } from "react";
import axios from "axios";
import { APILINK } from "../../config/consts";
export default function Login() {
  let log = localStorage.getItem("log")
  if (log == "yes") {
    window.location.href = "/"
  }
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  function handleLogin(event) {
    event.preventDefault();

    console.log(username);
    console.log(password);
    axios
      .get(`${APILINK}/login?username=${username}&password=${password}`)
      .then(function (response) {
        // handle success
        console.log(response.data.length);
        console.log(response.data[0]);
        if (response.data.length == 0) {
          alert("NETACNI PODACI");
        }
        let UserData = response.data[0];
        console.log(UserData.AuthUserID);
        console.log(UserData.length);
        if (username == UserData.AuthUserName) {
          console.log("yes");
          localStorage.setItem("AuthUserID", UserData.AuthUserID);
          localStorage.setItem("AuthLevelID", UserData.AuthLevelID);
          localStorage.setItem("AuthUserRealName", UserData.AuthUserRealName);
          localStorage.setItem("AuthUserMob", UserData.AuthUserMob);
          localStorage.setItem("AuthUserMail", UserData.AuthUserMail);
          localStorage.setItem("NoteToDriver", UserData.NoteToDriver);
          localStorage.setItem("Balance", UserData.Balance);
          localStorage.setItem("DriverID", UserData.DriverID);
          localStorage.setItem("log", "yes");
          window.location.href = "/";
        } else {
          alert("NETACNI PODACI");
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }
  return (
    <>
      <form onSubmit={handleLogin} action="/action_page.php" method="post">
        <div className="imgcontainer">
          <img src="/assets/logo512.png" alt="Avatar" className="avatar" />
        </div>
        <div className="container">
          <label htmlFor="uname">
            <b>Username</b>
          </label>
          <input
            type="text"
            placeholder="Enter Username"
            name="uname"
            required=""
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <label htmlFor="psw">
            <b>Password</b>
          </label>
          <input
            type="password"
            placeholder="Enter Password"
            name="psw"
            required=""
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit">Login</button>
        </div>
        <div className="container" style={{ backgroundColor: "#f1f1f1" }}></div>
      </form>
    </>
  );
}
