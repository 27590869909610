import { useState, useEffect } from "react";

import axios from "axios";
import { APILINK } from "../config/consts";

export default function ExtrasList({ detailsid }) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const getData = async () => {
            try {
                const response = await axios.get(
                    `${APILINK}/extras?detailsid=${detailsid}`
                );
                setData(response.data);
                setError(null);
            } catch (err) {
                setError(err.message);
                setData(null);
            } finally {
                setLoading(false);
            }
        };
        getData();
    }, []);

    return (
        <div className="App">
            {loading && <div>A moment please...</div>}
            {error && (
                <div>{`There is a problem fetching the post data - ${error}`}</div>
            )}
            <div>
                {data &&
                    data.map(({ ID, ServiceName, Qty }) => (
                        <div key={ID}>
                            <p><i className="bi bi-box-fill" />
{ServiceName} x {Qty}</p>
                        </div>
                    ))}
            </div>
        </div>
    );
}