import React from "react";
import axios from "axios";
import  cheerio  from "cheerio";
import './FlightStats.css'
function StatusiLeta({FlightNo, PickupDate}) {
    function flajtlink(flno, date) {


       
        let fglightno = flno;
        let Date = date;
        let cc;
        let fn;
        let year;
        let month;
        let day;
        let fs_link;
        
        fglightno = fglightno.replace(" ", '')
        fglightno = fglightno.replace("-", "");
     
        
        if (Number.isInteger(fglightno.substring(2, 2))) {
            cc = fglightno.substring(0, 1)
            fn = fglightno.substring(1)
        } else {
            cc = fglightno.substring(0, 2)
            fn = fglightno.substring(2)
        }
        if (cc == 'EZY') cc = 'U2';
        if (cc == 'EZS') cc = 'U2';
        if (cc == 'EJU') cc = 'U2';
        cc = cc.toUpperCase();
        Date = date;
        
        Date = Date.split("-");
        year = Date[0];
        month = Date[1];
        day = Date[2];
        fs_link = 'https://www.flightstats.com/v2/flight-tracker/' + cc + '/' + fn + '?year=' + year + '&month=' + month + '&date=' + day;
    //console.log(fs_link)
    return fs_link;


   }

  // let flajlink = flajtlink(FlightNo, PickupDate);
  //
    let flajlink = `https://dev.test.jamtransfer.com/backend/public/flight?flightno=${FlightNo}&date=${PickupDate}`;

const fetchTitles = async () => {
 try {
  const response = await        axios.get(flajlink);

        const html = response.data;

  const $ = cheerio.load(html);

  const titles = [];

  $('div.ticket__StatusContainer-sc-1rrbl5o-17').each((_idx, el) => {
   const title = $(el).text()
   titles.push(title)
  });

  return titles;
 } catch (error) {
  throw error;
 }
};
//let statusleta;

fetchTitles().then((titles) => document.getElementById(FlightNo).innerHTML = titles.join("%0A")
);

    return (
        <span><a className="transferstextdecor" href={flajlink}>{FlightNo} {PickupDate}</a><p id={FlightNo}></p></span>

    )
}

export default StatusiLeta;