import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Dashboard from "./pages/Dashboard/Dashboard";
import NoPage from "./pages/NoPage";
import Login from "./pages/Login/Login";
import Transfers from "./pages/Transfers/Transfers";
import TDetails from "./pages/TDetails/TDetails";
import WelcomeSign from "./pages/WelcomeSign/WelcomeSign";
import Finished from "./pages/Finished/Finished";
import Messages from "./pages/Messages/Messages";
import Contacts from "./pages/Contacts/Contacts";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Dashboard />} />
          <Route path="login" element={<Login />} />
          <Route path="transfers/:date" element={<Transfers />} />
          <Route path="tdetails/:detailsid" element={<TDetails />} />
          <Route path="welcomesign/:name" element={<WelcomeSign />} />
          <Route path="finished/:id" element={<Finished />} />
          <Route path="messages" element={<Messages />} />
          <Route path="contacts" element={<Contacts />} />

          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}