import { useState, useEffect } from "react";

import axios from "axios";
import { APILINK } from "../../config/consts";
import { useParams } from "react-router-dom";
export default function TDetails() {
  const { detailsid } = useParams();
  console.log("para" + detailsid);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  let AuthUserID = localStorage.getItem("AuthUserID");
  let DriverID = localStorage.getItem("DriverID");
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(
          `${APILINK}/transfers/details?detailsid=${detailsid}`
        );
        setData(response.data);
        setError(null);
      } catch (err) {
        setError(err.message);
        setData(null);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, []);

  return (
    <div className="App">
      <h1>Transfer Details</h1>
      {loading && <div>A moment please...</div>}
      {error && (
        <div>{`There is a problem fetching the post data - ${error}`}</div>
      )}
      <div>
        {data &&
          data.map(
            ({
              DetailsID,
              MOrderKey,
              MOrderID,
              VehicleType,
              PickupName,
              PickupDate,
              PickupAddress,
              FlightNo,
              FlightTime,
              DropName,
              DropAddress,
              PaxName,
              MPaxTel,
              PaxNo,
              StaffNote,
              FinalNote,
              DriverNotes,
              PaymentMethod,
            }) => (
              <div key={DetailsID}>
                <table className="table">
                  <tbody>
                    <tr>
                      <td>Order</td>
                      <td>
                        {MOrderKey}-{MOrderID}
                      </td>
                    </tr>
                    <tr>
                      <td>VehicleType</td>
                      <td>{VehicleType} pax</td>
                    </tr>
                    <tr>
                      <td>Pickup Name</td>
                      <td>{PickupName}</td>
                    </tr>
                    <tr>
                      <td>Pickup Date</td>
                      <td>{PickupDate}</td>
                    </tr>
                    <tr>
                      <td>Pickup Address</td>
                      <td>{PickupAddress}</td>
                    </tr>
                    <tr>
                      <td>FlightNo</td>
                      <td>{FlightNo}</td>
                    </tr>
                    <tr>
                      <td>FlightTime</td>
                      <td>{FlightTime}</td>
                    </tr>
                    <tr>
                      <td>Drop Name</td>
                      <td>{DropName}</td>
                    </tr>
                    <tr>
                      <td>Drop Address</td>
                      <td>{DropAddress}</td>
                    </tr>
                    <tr>
                      <td>Drop Address</td>
                      <td>{DropAddress}</td>
                    </tr>
                    <tr>
                      <td>Pax Name</td>
                      <td>{PaxName}</td>
                    </tr>
                    <tr>
                      <td>Pax tel</td>
                      <td>{MPaxTel}</td>
                    </tr>
                    <tr>
                      <td>Pax no</td>
                      <td>{PaxNo}</td>
                    </tr>
                    <tr>
                      <td>Notes</td>
                      <td>{StaffNote}</td>
                    </tr>
                    <tr>
                      <td>Final Notes</td>
                      <td>{FinalNote}</td>
                    </tr>
                    <tr>
                      <td>Driver Notes</td>
                      <td>{DriverNotes}</td>
                    </tr>
                  </tbody>
                </table>
                <div class="d-grid gap-2">
                  <a
                    href={`/welcomesign/${PaxName}`}
                    className="btn btn-primary"
                    type="button"
                  >
                    Welcome Sign
                  </a>
                  <a href={`/finished/${DetailsID}`} className="btn btn-primary" type="button">
                    Finished
                  </a>
                  <a href={`https://app.test.jamtransfer.com/back/nalog.php?id=${DetailsID}&OwnerID=${DriverID}`} className="btn btn-primary" type="button">
                    Putni nalog
                  </a>
                  {(() => {
                    if (PaymentMethod == 2) {
                      return (
                        //https://app.test.jamtransfer.com/back/nalog.php?id=162716&OwnerID=876
                        <a href={`https://app.test.jamtransfer.com/back/nalog.php?id=${DetailsID}&OwnerID=${DriverID}`} className="btn btn-primary" type="button">
                          Racun
                        </a>
                      );
                    } else if (PaymentMethod == 3) {
                      return (
                        <a href={`https://app.test.jamtransfer.com/back/nalog.php?id=${DetailsID}&OwnerID=${DriverID}`} className="btn btn-primary" type="button">
                          Racun
                        </a>
                      );
                    } else {
                      return <div></div>;
                    }
                  })()}
                </div>
              </div>
            )
          )}
      </div>
    </div>
  );
}
