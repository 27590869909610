import React from "react";
import TransCard from "../../components/TransCard";
import { Dan1 } from "../../config/consts";
import { Dan2 } from "../../config/consts";
import { Dan3 } from "../../config/consts";

export default function Dashboard() {
  console.log(Dan1);
  console.log(Dan2);
  console.log(Dan3);

  let log = localStorage.getItem("log");
  if (log != "yes") {
    window.location.href = "/login";
  }
  return (
    <>
      <TransCard PickupDate={Dan1} />
      <TransCard PickupDate={Dan2} />
      <TransCard PickupDate={Dan3} />
    </>
  );
}
