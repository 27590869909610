export const APILINK = "https://app.test.jamtransfer.com/backend/public";
//https://app.test.jamtransfer.com/backend/public/
//http://192.168.1.113/app/public
var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today.getFullYear();

today = mm + '/' + dd + '/' + yyyy;
//document.write(today);
export const Dan1 = yyyy + "-" + mm  + "-" + String((Number(dd)));
export const Dan2 = yyyy + "-" + mm  + "-" + String((Number(dd) + Number(1)));
export const Dan3 = yyyy + "-" + mm  + "-" + String((Number(dd) + Number(2)));