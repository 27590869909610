import React from "react";
import { useState, useEffect } from "react";

import axios from "axios";
import { APILINK } from "../../config/consts";
export default function Messages() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [count, setCount] = useState(0);
  let AuthUserID = localStorage.getItem("AuthUserID");
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(
          `${APILINK}/messages?subid=${AuthUserID}`
        );
        setData(response.data);
        setError(null);
      } catch (err) {
        setError(err.message);
        setData(null);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, [count]);


  

  return (
    <div>
      <h1>Messages</h1>
      {loading && <div>A moment please...</div>}
      {error && (
        <div>{`There is a problem fetching the post data - ${error}`}</div>
      )}
      <div>
        {data &&
          data.map(({ MessageID, SentDate, SentTime , Title, Body, OrderDetailsID}) => (
            <div key={MessageID} className="card" >
              <div className="card-body">
                <h5 className="card-title">{Title}</h5>
                <h6 className="card-subtitle mb-2 text-muted">{SentDate} {SentTime}</h6>
                <p className="card-text">
                  {Body}
                </p>
                <button onClick={()=> 
                        axios.get(`${APILINK}/messages/update?messageid=${MessageID}`)
                      .then(function (response) {
                        // handle success
                        console.log(response);
                        setCount(count + 1)
                        if (response.data) {
                            alert("Potvrdjen poruka")
                        } else {
                            alert("Nije potvrdjena poruka")
                        }
                      })
                      .catch(function (error) {
                        // handle error
                        console.log(error);
                      })
                      .then(function () {
                        // always executed
                      })
                      
                    
                } className="btn btn-primary">
                  Potvrdi
                </button>
                <a href={`/tdetails/${OrderDetailsID}`} className="btn btn-primary">
                  Vidi transfer
                </a>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
