import React from "react";
import { useParams } from "react-router-dom";
import TDetailsFinished from "../../components/TDetailsFinished";
import { useState } from "react";
import { APILINK } from "../../config/consts";
import axios from "axios";
export default function Finished() {
  const { id } = useParams();
  const [status, setStatus] = useState("10");
  const [amount, setAmounts] = useState("");
  const [notes, setNotes] = useState("");
  const [count, setCount] = useState(0)
  const handleChange = (event) => {
    setStatus(event.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setCount(count + 1)
    axios.get(`${APILINK}/transfers/finished?cashin=${amount}&status=${status}&subfinalnote=${notes}&detailsid=${id}`)
  .then(function (response) {
    // handle success
    console.log(response);
    console.log(response.data)
    if (response.data == 1) {
        alert("Uspesno zavrsen transfer")
    } else {
        alert("Nema promena na transfera")
    }
  })
  .catch(function (error) {
    // handle error
    console.log(error);
  })
  .then(function () {
    // always executed
  });
   // alert(`The name you entered was: ${status} ${amount} ${notes}`);
  };
  return (
    <div>
      <TDetailsFinished id={id} count={count} />
      <form onSubmit={handleSubmit}>
        <label htmlFor="exampleFormControlTextarea1" className="form-label">
          Status
        </label>
        <select
          className="form-select form-select-lg mb-3"
          aria-label=".form-select-lg example"
          value={status}
          onChange={handleChange}
        >
          <option selected="">Open this select menu</option>
          <option value={10}>Completed - Paid</option>
          <option value={20}>Completed - Not Paid</option>
          <option value={30}>No SHow</option>
          <option value={40}>Driver Error</option>
        </select>
        <div className="mb-3">
          <label htmlFor="exampleFormControlInput1" className="form-label">
            Amount
          </label>
          <input
            type="number"
            className="form-control"
            id="exampleFormControlInput1"
            placeholder=""
            value={amount}
            onChange={(e) => setAmounts(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="exampleFormControlTextarea1" className="form-label">
            Notes
          </label>
          <textarea
            className="form-control"
            id="exampleFormControlTextarea1"
            rows={3}
            defaultValue={""}
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          />
        </div>
        <div class="d-grid gap-2">
          <button
            onClick={handleSubmit}
            className="btn btn-primary"
            type="submit"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}
