import { Outlet, Link } from "react-router-dom";
import NavDrawer from "../components/NavDrawer";
const Layout = () => {

    let url = window.location.href;
    url = url.split("/")
    let lastElement = url.slice(-1);

    console.log(lastElement)
    if (lastElement == "login") {
        return (
            <>
              
              <Outlet />
            </>
          )
    }
  return (
    <>
      
<NavDrawer />
      <Outlet />
    </>
  )
};

export default Layout;