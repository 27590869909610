import React from "react";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { APILINK } from "../../config/consts";
import axios from "axios";
import StatusiLeta from "../../components/FlightStats";
import './Transfers.css'
import ExtrasList from "../../components/ExtrasList";
export default function Transfers() {

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    let AuthUserID = localStorage.getItem("AuthUserID")
    const { date } = useParams("");
    useEffect(() => {
        const getData = async () => {
            try {
                const response = await axios.get(
                    `${APILINK}/transfers?subdriver=${AuthUserID}&pickupdate=${date}`);
                setData(response.data);
                setError(null);
            } catch (err) {
                setError(err.message);
                setData(null);
            } finally {
                setLoading(false);
            }
        };
        getData();
    }, []);

    return (
        <div>
            <div >
                <h1>Transfers {date}</h1>
                {loading && <div>A moment please...</div>}
                {error && (
                    <div>{`There is a problem fetching the post data - ${error}`}</div>
                )}
                <div>
                    {data &&
                        data.map(({ DetailsID, SubPickupTime, PaxName, PickupName, DropName, VehiclesNo, PaxNo, MPaxTel, PickupAddress, DropAddress, PickupDate, FlightNo }) => (
                            <div key={DetailsID}>
                                <div className="card TransfersCard mt-3">
                                    <div className="card-body">
                                        <a href={"/tdetails/" + DetailsID} className="card-link">
                                            <h5 className="card-title">{SubPickupTime} {PaxName}</h5>
                                            <p className="card-text">
                                                {PickupName} <i className="bi bi-arrow-right-circle-fill" /> {DropName}

                                            </p>
                                            <p><i className="bi bi-person-circle" />
                                                {PaxNo} passengers {VehiclesNo} <i className="bi bi-car-front-fill" />
                                            </p>    </a>
                                        <p><a className="card-link" href={"tel:" + MPaxTel}><i className="bi bi-telephone-fill" />
                                            {MPaxTel}</a></p>
                                        <div><i className="bi bi-airplane-fill" />
                                            <StatusiLeta FlightNo={FlightNo} PickupDate={PickupDate} /></div>
                                            <div><ExtrasList detailsid={DetailsID} /></div>
                                        <p><a className="card-link" href={"https://www.google.rs/maps/search/" + PickupAddress + "," + PickupName}><i className="bi bi-house-door-fill" />
                                            {PickupAddress} {PickupName}</a></p>
                                        <p><a className="card-link" href={"https://www.google.rs/maps/search/" + DropAddress + "," + DropName}><i className="bi bi-cursor-fill" />
                                            {DropAddress} {DropName}</a></p>

                                    </div>
                                </div>
                            </div>

                        ))}
                </div>
            </div>


        </div>
    )
}