import React from "react";
import { useState, useEffect } from "react";
import './Contacts.css';
import axios from "axios";
import { APILINK } from "../../config/consts";

export default function Contacts() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
    let DriverID = localStorage.getItem("DriverID")
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(
          `${APILINK}/contacts?ownerid=${DriverID}`
        );
        setData(response.data);
        setError(null);
      } catch (err) {
        setError(err.message);
        setData(null);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, []);

  return (
    <div className="App">
      <h1>Contacts</h1>
      {loading && <div>A moment please...</div>}
      {error && (
        <div>{`There is a problem fetching the post data - ${error}`}</div>
      )}
      <ul className="list-group">
        {data &&
          data.map(({ AuthUserID, AuthUserRealName, AuthUserMob }) => (
            <li className="list-group-item" key={AuthUserID}>
              {" "}
              <a className="nodecor" href={`tel:${AuthUserMob}`}>
                {" "}
                <i className="bi bi-person-circle"></i>
 {AuthUserRealName} <i className="bi bi-telephone-fill"></i>
 {AuthUserMob}{" "}
              </a>{" "}
            </li>
          ))}
      </ul>
    </div>
  );
}
