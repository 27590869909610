import React from "react";
import { useParams } from "react-router-dom";
export default function WelcomeSign() {
    const { name } = useParams();
  return (
    <div class="container">
      <div class="jumbotron">
        <h1>{name}</h1>
     
      </div>

    </div>
  );
}
