import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import './TransCard.css'
import axios from "axios";
import { APILINK } from "../config/consts";
export default function TransCard({ PickupDate }) {
  const [data, setData] = useState(null);
  const [TransfersNumber, setTransfersNumber] = useState(0);
  const [onetransfer, setonetransfer] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  let AuthUserID = localStorage.getItem("AuthUserID");
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(
          `${APILINK}/transfers?subdriver=${AuthUserID}&pickupdate=${PickupDate}`
        );
        setData(response.data);
        let tdata = response.data;
        let tno = tdata.length;
        setTransfersNumber(tno);

        setError(null);
      } catch (err) {
        setError(err.message);
        setData(null);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, []);

  return (
    <div className="card m-4">
      <div className="card-body">
      <a className="nodecor" href={`/transfers/${PickupDate}`}>  <h5 className="card-title">Transferi {PickupDate}</h5>
        <h6 className="card-subtitle mb-2 text-muted">
          {TransfersNumber} transfera za {PickupDate}
        </h6></a>
        <p className="card-text">
          {loading && <div>A moment please...</div>}
          {error && (
            <div>{`There is a problem fetching the post data - ${error}`}</div>
          )}
          <ul>
            {data &&
              data.map(
                ({
                  DetailsID,
                  PickupDate,
                  PickupName,
                  DropName,
                  PickupTime,
                }) => (
                  <li key={DetailsID}>
                  <a className="nodecor" href={`/tdetails/${DetailsID}`}> <span>
                      {PickupTime} {PickupName}{" "}
                      <i className="bi bi-arrow-right" /> {DropName}
                    </span></a> 
                  </li>
                )
              )}
          </ul>
        </p>
        <a href={"/transfers/" + PickupDate} className="card-link">
          Vidi sve
        </a>
      </div>
    </div>
  );
}
