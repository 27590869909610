import React from "react";
//port './NavDrawer.css'
export default function NavDrawer() {
let AuthUserID = localStorage.getItem("AuthUserID")
let DriverID = localStorage.getItem("DriverID")

    return (
        <>
            <>
                <nav className="navbar navbar-expand-sm bg-dark navbar-dark fixed-top">
                    <div className="container-fluid">
                        <a
                            className="btn btn-primary"
                            data-bs-toggle="offcanvas"
                            href="#offcanvasExample"
                            role="button"
                            aria-controls="offcanvasExample"
                        >
                            Menu
                        </a>
                        <a className="navbar-brand" href="#">
                           JamTransfer
                        </a>
                                        </div>
                </nav>
                <div className="container-fluid" style={{marginTop: 80}}>

                 </div>
            </>
            <>


                <div
                    className="offcanvas offcanvas-start"
                    tabIndex={-1}
                    id="offcanvasExample"
                    aria-labelledby="offcanvasExampleLabel"
                >
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title" id="offcanvasExampleLabel">
                            Menu
                        </h5>
                        <button
                            type="button"
                            className="btn-close text-reset"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                        />
                    </div>
                    <div className="offcanvas-body">
                        <div>
                        
                        </div>
                        <div class="d-grid gap-2">
  <a href="/" class="btn btn-primary" type="button">Dashboard</a>
  <a href="/messages" className="btn btn-primary" type="button">Messages</a>
  <a href="/contacts" className="btn btn-primary" type="button">Contacts</a>
  <a href={`https://app.test.jamtransfer.com/back/expenses.php?driverid=${AuthUserID}}&ownerid=${DriverID}`} className="btn btn-primary" type="button">Expenses</a>
        
</div>
                    </div>
                </div>
            </>


        </>

    )
}