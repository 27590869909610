import React from "react";

import { useState, useEffect } from "react";

import axios from "axios";
import { APILINK } from "../config/consts";

export default function TDetailsFinished({ id, count }) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(
          `${APILINK}/transfers/details?detailsid=${id}&count=${count}`
        );
        setData(response.data);
        setError(null);
      } catch (err) {
        setError(err.message);
        setData(null);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, [count]);

  return (
    <div className="App">
      <h1>Transfer Finished</h1>
      {loading && <div>A moment please...</div>}
      {error && (
        <div>{`There is a problem fetching the post data - ${error}`}</div>
      )}
      <div>
        {data &&
          data.map(({ DetailsID, PaxName, CashIn, SubFinalNote }) => (
            <div key={DetailsID}>
              <div>{PaxName}</div>
              <div>{CashIn}</div>
              <div>{SubFinalNote}</div>
            </div>
          ))}
      </div>
    </div>
  );
}
